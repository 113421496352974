import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import * as firebase from 'firebase';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
//import { AdmobFreeService } from './service/admobfree.service.ts.service';
//import { AdMobFree } from '@ionic-native/admob-free/ngx';


firebase.initializeApp({
  apiKey: "AIzaSyCe58TT7Rvku0Kwfjj247cYvkLQlZgoAyQ",
  authDomain: "biologia-d60b1.firebaseapp.com",
  databaseURL: "https://biologia-d60b1.firebaseio.com",
  projectId: "biologia-d60b1",
  storageBucket: "biologia-d60b1.appspot.com",
  messagingSenderId: "961520429997",
  appId: "1:961520429997:web:b32e40586e4012fb4f8f24",
  measurementId: "G-85857E84EN"
});
firebase.analytics();


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

